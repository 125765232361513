import { CartItem, CartPromoView, ClientPaymentIntentView } from '@zola/svc-marketplace-ts-types';

import { ConfirmationToken } from '@stripe/stripe-js';
import { Action } from 'redux';

import {
  appliedPromoCode,
  receivedVendorCart,
  receivedAddPackageToCart,
  removedPromoCode,
  submittedOrder,
  receivedAddPackageToMultipleStorefronts,
  receivedPaymentIntent,
  receivedConfirmationToken,
} from '~/actions/vendors/types/vendorCartActionTypes';

export type CartState = {
  // From cart view
  id: string | undefined;
  checksum: string | undefined;
  promo: CartPromoView | null;
  cartItems: CartItem[];

  // Other values
  loaded: boolean;
  purchased: boolean;
  paymentIntent?: ClientPaymentIntentView;
  confirmationToken?: ConfirmationToken;
};

export const initialState: CartState = {
  id: undefined,
  cartItems: [],
  checksum: undefined,
  loaded: false,
  purchased: false,
  promo: null,
  paymentIntent: undefined,
  confirmationToken: undefined,
};

const cartReducer = (state = initialState, action?: Action): CartState => {
  if (!action) {
    return state;
  }
  if (
    receivedAddPackageToCart.match(action) ||
    receivedVendorCart.match(action) ||
    appliedPromoCode.match(action) ||
    removedPromoCode.match(action) ||
    receivedAddPackageToMultipleStorefronts.match(action)
  ) {
    // All these give us a Cart View
    const { payload: cart } = action;
    return { ...state, ...cart, loaded: true, purchased: false };
  }

  if (submittedOrder.match(action)) {
    // we're actually ignoring the payload, just empty the cart and set the purchased property
    return { ...state, ...initialState, loaded: true, purchased: true, promo: null };
  }

  if (receivedPaymentIntent.match(action)) {
    return { ...state, paymentIntent: action.payload };
  }

  if (receivedConfirmationToken.match(action)) {
    return { ...state, confirmationToken: action.payload };
  }

  return state;
};

export default cartReducer;
