import { Action } from 'redux';

import {
  receivedOfficiantDetails,
  updatedOfficiantDetails,
} from '~/actions/types/officiantActionTypes';
import { OfficiantDetails } from '~/types/responseTypes';
import { setStorefrontEntityObject, StorefrontEntityState } from '~/util/reducerUtils';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: StorefrontEntityState<OfficiantDetails> = {
  // Look up an officiant by id
  byId: {},

  // all the IDs of all the officiants we've loaded
  allIds: [],

  // Map storefront ids to officiant ids
  storefrontToId: {},
};

const officiantReducer = (
  state = initialState,
  action: Action
): StorefrontEntityState<OfficiantDetails> => {
  if (!action) {
    return state;
  }

  if (receivedOfficiantDetails.match(action) || updatedOfficiantDetails.match(action)) {
    return setStorefrontEntityObject(state, action.payload);
  }

  return state;
};

export default officiantReducer;
