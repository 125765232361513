import { userAwarenessReducer as userAwareness } from '@zola-helpers/client/dist/es/redux/userAwareness';
import { toastsV2Reducer } from '@zola/zola-ui/src/reducers/toastsV2Reducer';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import address from './addressReducer';
import bookedVendors from './bookedVendorsReducer';
import couplesReducer from './couples';
import couplesInquiries from './couplesInquiriesReducer';
import bakers from './entities/bakers';
import beauticians from './entities/beauticians';
import caterers from './entities/caterers';
import catererStories from './entities/catererStories';
import extras from './entities/extras';
import favorites from './entities/favorites';
import florists from './entities/florists';
import musicians from './entities/musicians';
import officiants from './entities/officiants';
import photographer from './entities/photographers';
import promoModules from './entities/promoModules';
import { storefrontBookings } from './entities/storefrontBookings';
import vendorPreferences from './entities/vendorPreferences';
import videoGalleryReducer from './entities/videoGalleryReducer';
import videographers from './entities/videographers';
import planners from './entities/weddingPlanners';
import images from './imagesReducer';
import inquiryJewelReducer from './inquiryJewelReducer';
import inquiryMessagesReducer from './inquiryMessagesReducer';
import inquiry from './inquiryReducer';
import notifications from './notificationsReducer';
import packages from './packagesReducer';
import spaces from './spacesReducer';
import storefrontPreferredVendors from './storefrontPreferredVendorsReducer';
import storefront from './storefrontReducer';
import taxonomy from './taxonomyReducer';
import tempImages from './tempImagesReducer';
import tempPhotoCredits from './ui/tempPhotoCreditsReducer';
import marketplaceToastsReducer from './ui/toastsReducer';
import uiWeddingReducer from './ui/weddings';
import vendorAccount from './vendorAccountReducer';
import vendorAccountSettings from './vendorAccountSettingsReducer';
import vendorCommunity from './vendorCommunityReducer';
import vendorInquiries from './vendorInquiriesReducer';
import vendorMailingListSettings from './vendorMailingListSettingsReducer';
import vendorOnboarding from './vendorOnboardingReducer';
import vendorsReducer from './vendors';
import vendorStorefrontImages from './vendorStorefrontImagesReducer';
import vendorStorefrontPublication from './vendorStorefrontPublicationReducer';
import vendorStorefront from './vendorStorefrontReducer';
import vendorUserContext from './vendorUserContextReducer';
import weddingPreferencesReducer from './weddingPreferencesReducer';

const uiReducers = combineReducers({
  weddings: uiWeddingReducer,
  tempPhotoCredits,
  inquiryJewels: inquiryJewelReducer,
  inquiryMessages: inquiryMessagesReducer,
  toasts: marketplaceToastsReducer,
});

const entitiesReducers = combineReducers({
  bakers,
  beauticians,
  caterers,
  extras,
  favorites,
  florists,
  musicians,
  officiants,
  planners,
  promos: promoModules,
  catererStories,
  spaces,
  storefrontBookings,
  videoGallery: videoGalleryReducer,
  videographers,
  vendorPreferences,
});

export type EntityRootState = ReturnType<typeof entitiesReducers>;

export const rootState = combineReducers({
  storefront,
  address,
  bookedVendors,
  couplesInquiries,
  form,
  images,
  inquiry,
  notifications,
  tempImages,
  taxonomy,
  toasts: toastsV2Reducer,
  vendorAccount,
  vendorAccountSettings,
  vendorMailingListSettings,
  vendorInquiries,
  vendorOnboarding,
  vendorStorefrontImages,
  vendorStorefront,
  vendorCommunity,
  photographer,
  packages,
  preferredVendors: storefrontPreferredVendors,
  ui: uiReducers,
  entities: entitiesReducers,
  vendorStorefrontPublication,
  weddingPreferences: weddingPreferencesReducer,
  vendors: vendorsReducer,
  couples: couplesReducer,
  userAwareness,
  vendorUserContext,
});

export default rootState;

export type RootState = ReturnType<typeof rootState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

/**
 * A utility type to get the function signature of a dispatched thunk
 *
 * @example:
 * const thunkAction = (): AppThunk<Promise<string>> {...};
 *
 * DispatchedThunk<typeof thunkAction> gives the type: () => Promise<string>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DispatchedThunk<Type extends (...args: any[]) => AppThunk<any>> = (
  ...args: Parameters<Type>
) => ReturnType<ReturnType<Type>>;

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export type GetState = () => RootState;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
