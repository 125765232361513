/* eslint-disable @typescript-eslint/no-restricted-imports */
import { parseDate } from '@zola-helpers/client/dist/es/util/dateUtils';
import { ExactDateView } from '@zola/svc-marketplace-ts-types';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isTodayExtension from 'dayjs/plugin/isToday';
import isYesterdayExtension from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

export {
  isValidDate,
  isBeforeDate,
  isAfterDate,
  parseDate,
  parseDateUtc,
  getDate,
  getDateUtc,
  getDateMilliseconds,
  getDateMillisecondsUtc,
  getDaysInMonth,
  addMinutes,
  addDays,
  addMonths,
  addYears,
  formatDate,
  formatDateUtc,
  formatCalendarFrom,
  isInTheFuture,
  isInThePast,
  getDifferenceInDays,
} from '@zola-helpers/client/dist/es/util/dateUtils';

dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isTodayExtension);
dayjs.extend(isYesterdayExtension);

export type DateValue = Date | number | string;

/**
 * Gets the number of days since the Unix Epoch
 *
 * @param date The date to get the value of
 * @returns The number of days since the Unix Epoch
 */
export const getDateDays = (date?: DateValue): number => {
  return Math.trunc(dayjs(date).valueOf() / 86400000); // 1000 ms / 60 sec / 60 min / 24 hours
};

/**
 * Formats a date as a simplified extended ISO string
 *
 * @param date - The date to format
 * @returns A string in simplified extended ISO format, such as "2021-03-20T00:00:00.000Z"
 */
export const formatDateIso = (date: DateValue): string => {
  return dayjs(date).toISOString();
};

/**
 * Formats a date as a string representing the time in relation to another date
 *
 * @param date - The date to format
 * @param fromDate - The date to use as a reference
 * @param noSuffix - If true, the value is returned without the suffix, i.e.
 * "ago" or "in". For example, "a day" vs "a day ago" or "4 days" vs "in 4 days"
 * @returns A string representing a relative time, such as "a day ago" or "in 6 months"
 */
export const formatDateFrom = (date: DateValue, fromDate: DateValue, noSuffix = false): string => {
  return dayjs(date).from(fromDate, noSuffix);
};

/**
 * Get the date of the start of today (00:00)
 *
 * @returns A new date as local time
 */
export const getStartOfToday = (): Date => {
  return dayjs().startOf('day').toDate();
};

/**
 * Gets the upcoming date of a specified hour (either today or tomorrow)
 *
 * @param number - the specified hour to use
 * @returns The number of milliseconds since the Unix Epoch
 */
const getNextSpecifiedHour = (hour: number): number => {
  const todaysReset = dayjs().utc(true).startOf('day').add(hour, 'hours');
  if (todaysReset > dayjs()) return todaysReset.valueOf();
  return todaysReset.add(1, 'day').valueOf();
};

// Real Wedding Landing Page resets at 09:00 UTC daily
export const getNextRWReset = (): number => getNextSpecifiedHour(9);

/**
 * Formats a past time relative to now using date JS relative time formatting.
 *
 *
 * |Range|Sample Output|
 * | -- | -- |
 * |0 to 44 seconds|a few seconds ago|
 * |45 to 89 seconds|a minute ago|
 * |90 seconds to 44 minutes|2 minutes ago ... 44 minutes ago|
 * |45 to 89 minutes|an hour ago|
 * |90 minutes to 21 hours|2 hours ago ... 21 hours ago|
 * |22 to 35 hours|a day ago|
 * |36 hours to 25 days|2 days ago ... 25 days ago|
 * |26 to 45 days|a month ago|
 * |46 days to 10 months|2 months ago ... 10 months ago|
 * |11 months to 17months|a year ago|
 * |18 months+|2 years ago ... 20 years ago|
 *
 */
export const formatTimeAgo = (date: DateValue): string => {
  return dayjs(date).fromNow();
};

export const isToday = (date: DateValue): boolean => {
  return dayjs(date).isToday();
};

export const isYesterday = (date: DateValue): boolean => {
  return dayjs(date).isYesterday();
};

export const getDateFromExactDate = (date: ExactDateView): Date => {
  return parseDate(`${date.year}-${date.month}-${date.day}`, 'YYYY-M-D');
};

export const getEndOfMonth = (date: DateValue) => {
  return dayjs(date).endOf('month').toDate();
};
