import { createAction } from '@reduxjs/toolkit';

import { OfficiantDetails } from '~/types/responseTypes';

const REQUESTING_OFFICIANT_DETAILS_BY_STOREFRONT =
  'zola/officiant/REQUESTING_OFFICIANT_DETAILS_BY_STOREFRONT';
const RECEIVED_OFFICIANT_DETAILS = 'zola/officiant/RECEIVED_OFFICIANT_DETAILS';
const UPDATING_OFFICIANT_DETAILS = 'zola/officiant/UPDATING_OFFICIANT_DETAILS';
const UPDATED_OFFICIANT_DETAILS = 'zola/officiant/UPDATED_OFFICIANT_DETAILS';

export const requestingOfficiantDetailsByStorefront = createAction(
  REQUESTING_OFFICIANT_DETAILS_BY_STOREFRONT
);
export const receivedOfficiantDetails = createAction<OfficiantDetails>(RECEIVED_OFFICIANT_DETAILS);
export const updatingOfficiantDetails = createAction(UPDATING_OFFICIANT_DETAILS);
export const updatedOfficiantDetails = createAction<OfficiantDetails>(UPDATED_OFFICIANT_DETAILS);
