import {
  AddToCartRequest,
  VendorCreditCartView,
  CheckoutRequest,
  CheckoutResultView,
  ClientPaymentIntentView,
} from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

const REQUESTING_ADD_PACKAGE_TO_CART = 'zola/vendor/REQUESTING_ADD_PACKAGE_TO_CART';
const REQUESTING_ADD_PAY_AS_YOU_GO_PACKAGE_TO_CART =
  'zola/vendor/REQUESTING_ADD_PAY_AS_YOU_GO_PACKAGE_TO_CART';
const RECEIVED_ADD_PACKAGE_TO_CART = 'zola/vendor/RECEIVED_ADD_PACKAGE_TO_CART';

const REQUESTING_VENDOR_CART = 'zola/vendor/REQUESTING_VENDOR_CART';
const RECEIVED_VENDOR_CART = 'zola/vendor/RECEIVED_VENDOR_CART';

const RECEIVED_PAYMENT_INTENT = 'zola/vendor/RECEIVED_PAYMENT_INTENT';
const RECEIVED_CONFIRMATION_TOKEN = 'zola/vendor/RECEIVED_CONFIRMATION_TOKEN';

const SUBMITTING_ORDER = 'zola/vendor/SUBMITTING_ORDER';
const SUBMITTED_ORDER = 'zola/vendor/SUBMITTED_ORDER';

const APPLYING_PROMO_CODE = 'zola/vendor/APPLYING_PROMO_CODE';
const APPLIED_PROMO_CODE = 'zola/vendor/APPLIED_PROMO_CODE';

const REMOVING_PROMO_CODE = 'zola/vendor/REMOVING_PROMO_CODE';
const REMOVED_PROMO_CODE = 'zola/vendor/REMOVED_PROMO_CODE';

const REQUESTING_ADD_PACKAGE_TO_MULTIPLE_STOREFRONTS =
  'zola/vendor/REQUESTING_ADD_PACKAGE_TO_MULTIPLE_STOREFRONTS';
const RECEIVED_ADD_PACKAGE_TO_MULTIPLE_STOREFRONTS =
  'zola/vendor/RECEIVED_ADD_PACKAGE_TO_MULTIPLE_STOREFRONTS';

export const requestingAddPackageToCart = createAction<AddToCartRequest>(
  REQUESTING_ADD_PACKAGE_TO_CART
);
export const requestingAddPayAsYouPackageGoToCart = createAction<{ inquiryUuid: string }>(
  REQUESTING_ADD_PAY_AS_YOU_GO_PACKAGE_TO_CART
);
export const receivedAddPackageToCart = createAction<VendorCreditCartView>(
  RECEIVED_ADD_PACKAGE_TO_CART
);

export const requestingVendorCart = createAction(REQUESTING_VENDOR_CART);
export const receivedVendorCart = createAction<VendorCreditCartView>(RECEIVED_VENDOR_CART);

export const receivedPaymentIntent = createAction<ClientPaymentIntentView>(RECEIVED_PAYMENT_INTENT);
export const receivedConfirmationToken = createAction(RECEIVED_CONFIRMATION_TOKEN);

export const submittingOrder = createAction<CheckoutRequest>(SUBMITTING_ORDER);
export const submittedOrder = createAction<CheckoutResultView>(SUBMITTED_ORDER);

export const applyingPromoCode = createAction(APPLYING_PROMO_CODE);
export const appliedPromoCode = createAction<VendorCreditCartView>(APPLIED_PROMO_CODE);

export const removingPromoCode = createAction(REMOVING_PROMO_CODE);
export const removedPromoCode = createAction<VendorCreditCartView>(REMOVED_PROMO_CODE);

export const requestingAddPackageToMultipleStorefronts = createAction(
  REQUESTING_ADD_PACKAGE_TO_MULTIPLE_STOREFRONTS
);

export const receivedAddPackageToMultipleStorefronts = createAction<VendorCreditCartView>(
  RECEIVED_ADD_PACKAGE_TO_MULTIPLE_STOREFRONTS
);
