import { createAction } from '@reduxjs/toolkit';

import { ExtrasDetails } from '~/types/responseTypes';

const REQUESTING_EXTRAS_DETAILS_BY_STOREFRONT =
  'zola/extras/REQUESTING_EXTRAS_DETAILS_BY_STOREFRONT';
const RECEIVED_EXTRAS_DETAILS = 'zola/extras/RECEIVED_EXTRAS_DETAILS';
const UPDATING_EXTRAS_DETAILS = 'zola/extras/UPDATING_EXTRAS_DETAILS';
const UPDATED_EXTRAS_DETAILS = 'zola/extras/UPDATED_EXTRAS_DETAILS';

export const requestingExtrasDetailsByStorefront = createAction(
  REQUESTING_EXTRAS_DETAILS_BY_STOREFRONT
);
export const receivedExtrasDetails = createAction<ExtrasDetails>(RECEIVED_EXTRAS_DETAILS);
export const updatingExtrasDetails = createAction(UPDATING_EXTRAS_DETAILS);
export const updatedExtrasDetails = createAction<ExtrasDetails>(UPDATED_EXTRAS_DETAILS);
