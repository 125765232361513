import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  VendorTaxonomyKey,
  isVendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

import type { Action } from 'redux';

import {
  receivedUserSettings,
  setUserSettings,
  UserSettingsObjectType,
} from '~/actions/couples/types/userSettingsActionTypes';

export interface CoupleUserSettingsState {
  settings: UserSettingsObjectType;
  loaded: boolean;
}

export const initialState: CoupleUserSettingsState = {
  // couples/userSettings/
  settings: {},
  loaded: false,
};

const userSettingsReducer = (state = initialState, action: Action): CoupleUserSettingsState => {
  if (!action) return state;

  if (receivedUserSettings.match(action) || setUserSettings.match(action)) {
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload,
      },
      loaded: true,
    };
  }

  return state;
};

// string keys for setting (UserSettingView)
export const SAW_TOP_PICKS_VENUES = 'marketplace:search:saw-top-picks-venues';
const SAW_TOP_PICKS_FLORISTS = 'marketplace:search:saw-top-picks-florists';
const SAW_TOP_PICKS_PHOTOGRAPHERS = 'marketplace:search:saw-top-picks-photographers';
const SAW_TOP_PICKS_VIDEOGRAPHERS = 'marketplace:search:saw-top-picks-videographers';
const SAW_TOP_PICKS_MUSICIANS = 'marketplace:search:saw-top-picks-musicians';
const SAW_TOP_PICKS_BAR_SERVICES = 'marketplace:search:saw-top-picks-bar-services';
const SAW_TOP_PICKS_BAKERS = 'marketplace:search:saw-top-picks-bakers';
const SAW_TOP_PICKS_CATERERS = 'marketplace:search:saw-top-picks-caterers';
const SAW_TOP_PICKS_BEAUTY = 'marketplace:search:saw-top-picks-beauty';
const SAW_TOP_PICKS_PLANNERS = 'marketplace:search:saw-top-picks-planners';
const SAW_TOP_PICKS_OFFICIANTS = 'marketplace:search:saw-top-picks-officiants';
const SAW_TOP_PICKS_EXTRAS = 'marketplace:search:saw-top-picks-extras';

const SEARCHABLE_VENDOR_TAXONOMY_KEY_TO_TOP_PICKS = getVendorTaxonomySwitchFunc<
  SearchableVendorTaxonomyKey,
  string
>({
  [BANDS_DJS_TAXONOMY_KEY]: SAW_TOP_PICKS_MUSICIANS,
  [BAR_SERVICES_TAXONOMY_KEY]: SAW_TOP_PICKS_BAR_SERVICES,
  [CAKES_DESSERTS_TAXONOMY_KEY]: SAW_TOP_PICKS_BAKERS,
  [CATERING_TAXONOMY_KEY]: SAW_TOP_PICKS_CATERERS,
  [EXTRAS_TAXONOMY_KEY]: SAW_TOP_PICKS_EXTRAS,
  [FLORISTS_TAXONOMY_KEY]: SAW_TOP_PICKS_FLORISTS,
  [HAIR_MAKEUP_TAXONOMY_KEY]: SAW_TOP_PICKS_BEAUTY,
  [OFFICIANTS_TAXONOMY_KEY]: SAW_TOP_PICKS_OFFICIANTS,
  [PHOTOGRAPHERS_TAXONOMY_KEY]: SAW_TOP_PICKS_PHOTOGRAPHERS,
  [PLANNERS_TAXONOMY_KEY]: SAW_TOP_PICKS_PLANNERS,
  [VENUES_TAXONOMY_KEY]: SAW_TOP_PICKS_VENUES,
  [VIDEOGRAPHERS_TAXONOMY_KEY]: SAW_TOP_PICKS_VIDEOGRAPHERS,
});

export const vendorTypeKeyToUserSettingsKey = (key: VendorTaxonomyKey | undefined) => {
  if (isVendorTaxonomyKey(key)) return SEARCHABLE_VENDOR_TAXONOMY_KEY_TO_TOP_PICKS(key);
  return '';
};

export const ALL_TOP_PICKS_USER_SETTINGS = [
  SAW_TOP_PICKS_BAKERS,
  SAW_TOP_PICKS_BAR_SERVICES,
  SAW_TOP_PICKS_BEAUTY,
  SAW_TOP_PICKS_CATERERS,
  SAW_TOP_PICKS_FLORISTS,
  SAW_TOP_PICKS_MUSICIANS,
  SAW_TOP_PICKS_PHOTOGRAPHERS,
  SAW_TOP_PICKS_PLANNERS,
  SAW_TOP_PICKS_VENUES,
  SAW_TOP_PICKS_VIDEOGRAPHERS,
];

export default userSettingsReducer;
