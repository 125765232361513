import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace';
import { CoupleDetailView, VendorPreferenceView } from '@zola/svc-marketplace-ts-types';

import { AnyAction } from '@reduxjs/toolkit';
import _camelCase from 'lodash/camelCase';

import {
  receivedMiniQuiz,
  receivedMiniQuizError,
  receivedMiniQuizGuest,
  receivedTaxonomyKey,
} from '~/actions/couples/types/miniQuizActionTypes';
import { initializeExpandedWeddingDates } from '~/components/common/form/ExpandedDateFields/initializeExpandedWeddingDates';
import { MiniQuizFormValues } from '~/components/common/modals/MiniQuizModal/miniQuizTypes';

export type MiniQuizTaxonomyState = {
  loaded: boolean;
  error: boolean;
  values: MiniQuizFormValues;
};

export type MiniQuizState = Record<SearchableVendorTaxonomyKey, MiniQuizTaxonomyState> & {
  activeTaxonomyKey?: SearchableVendorTaxonomyKey;
};

const initialQuizState: MiniQuizTaxonomyState = {
  loaded: false,
  error: false,
  values: {},
};

export const initialState: MiniQuizState = {
  [BANDS_DJS_TAXONOMY_KEY]: { ...initialQuizState },
  [BAR_SERVICES_TAXONOMY_KEY]: { ...initialQuizState },
  [CAKES_DESSERTS_TAXONOMY_KEY]: { ...initialQuizState },
  [CATERING_TAXONOMY_KEY]: { ...initialQuizState },
  [EXTRAS_TAXONOMY_KEY]: { ...initialQuizState },
  [FLORISTS_TAXONOMY_KEY]: { ...initialQuizState },
  [HAIR_MAKEUP_TAXONOMY_KEY]: { ...initialQuizState },
  [OFFICIANTS_TAXONOMY_KEY]: { ...initialQuizState },
  [PHOTOGRAPHERS_TAXONOMY_KEY]: { ...initialQuizState },
  [PLANNERS_TAXONOMY_KEY]: { ...initialQuizState },
  [VENUES_TAXONOMY_KEY]: { ...initialQuizState },
  [VIDEOGRAPHERS_TAXONOMY_KEY]: { ...initialQuizState },
  activeTaxonomyKey: undefined,
};

const detailsToQuizValues = (
  taxonomyKey: SearchableVendorTaxonomyKey,
  coupleDetailView: CoupleDetailView
): MiniQuizFormValues => {
  const vendorPreference: VendorPreferenceView | undefined =
    coupleDetailView.vendorPreferences[_camelCase(taxonomyKey)];

  let values: MiniQuizFormValues = {
    showFlexibleDateSelection: taxonomyKey === VENUES_TAXONOMY_KEY,

    guestCount: coupleDetailView.guestCount ?? undefined,
    flexibleOnGuestCount: coupleDetailView.flexibleOnGuestCount,

    flexibleOnWeddingPrice: coupleDetailView.flexibleOnWeddingBudget,
    weddingEndPrice: coupleDetailView.weddingEndPriceCents
      ? coupleDetailView.weddingEndPriceCents / 100
      : undefined,

    serviceEndPrice: vendorPreference?.serviceEndPriceCents
      ? vendorPreference.serviceEndPriceCents / 100
      : undefined,
    flexibleOnPrice: vendorPreference?.flexibleOnServiceBudget,
    vendorCategory: taxonomyKey,
  };

  values = initializeExpandedWeddingDates(values, coupleDetailView);

  if (
    coupleDetailView.address &&
    coupleDetailView.address.city &&
    coupleDetailView.address.stateProvince &&
    // make sure the city maps to a market
    coupleDetailView.address.vendorMarket
  ) {
    values = {
      ...values,
      weddingLocation: {
        city: coupleDetailView.address.city,
        stateProvince: coupleDetailView.address.stateProvince,
      },
    };
  }
  return values;
};

const miniQuizReducer = (state = initialState, action: AnyAction): MiniQuizState => {
  if (receivedMiniQuiz.match(action)) {
    const { taxonomyKey, details } = action.payload;
    return {
      ...state,
      [taxonomyKey]: {
        loaded: true,
        error: false,
        values: detailsToQuizValues(taxonomyKey, details),
      },
    };
  }
  if (receivedMiniQuizGuest.match(action)) {
    const { taxonomyKey } = action.payload;

    return {
      ...state,
      [taxonomyKey]: {
        loaded: true,
        error: false,
        values: {},
      },
    };
  }
  if (receivedTaxonomyKey.match(action)) {
    const { taxonomyKey } = action.payload;
    return {
      ...state,
      activeTaxonomyKey: taxonomyKey,
    };
  }
  if (receivedMiniQuizError.match(action)) {
    const { taxonomyKey } = action.payload;

    return {
      ...state,
      [taxonomyKey]: {
        loaded: false,
        error: true,
        values: {},
      },
    };
  }

  return state;
};

export default miniQuizReducer;
