import { Action } from 'redux';

import { receivedExtrasDetails, updatedExtrasDetails } from '~/actions/types/extrasActionTypes';
import { ExtrasDetails } from '~/types/responseTypes';
import { setStorefrontEntityObject, StorefrontEntityState } from '~/util/reducerUtils';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: StorefrontEntityState<ExtrasDetails> = {
  // Look up extras by id
  byId: {},

  // all the IDs of all the extras we've loaded
  allIds: [],

  // Map storefront ids to extras ids
  storefrontToId: {},
};

const extrasReducer = (
  state = initialState,
  action: Action
): StorefrontEntityState<ExtrasDetails> => {
  if (!action) {
    return state;
  }

  if (receivedExtrasDetails.match(action) || updatedExtrasDetails.match(action)) {
    return setStorefrontEntityObject(state, action.payload);
  }

  return state;
};

export default extrasReducer;
